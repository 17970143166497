import { Avatar, Box, Typography, styled } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';

import botAbatar from '../../assets/images/bot-avatar.png';
import { StyledListItemAvatar, StyledContent } from './Common';
import React, { useEffect, useState } from 'react';
import { BotAction } from '../../types';
import useTypingControl from './useTypingControl';
import { MessagePropsBase } from './types';
import { default as MessageActionsContainer } from './MessageActions/Container';

const MessageContent = styled(Typography)(({ theme }) => ({
  color: '#4B4B4B',
  "& a": { wordBreak: "break-all" }
}));

type MessageProps = MessagePropsBase & {
  onClickAction?: (action: BotAction) => void;
  onFinishTyping?: () => void;
  actions?: Array<BotAction>;
  typingAnimation?: boolean;
};

const MessageContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row'
}));

type TextMessageProps = {
  message: string;
  onFinish: (element: HTMLElement | null) => void;
  typingAnimation?: boolean;
};
const TextMessage = (props: TextMessageProps) => {
  if (!props.typingAnimation) {
    return <TextWithoutTypingAnimation {...props} />;
  }

  const sequence = [props.message, props.onFinish];
  return (
    <MessageContent variant="body1">
      <TypeAnimation
        style={{ whiteSpace: 'pre-line' }}
        sequence={sequence}
        wrapper="span"
        speed={99}
        repeat={0}
        cursor={false}
      />
    </MessageContent>
  );
};

const HtmlMessage = (props: TextMessageProps) => {
  useEffect(() => {
    props.onFinish(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  
  return (
    <MessageContent
      variant="body1"
      dangerouslySetInnerHTML={{ __html: props.message }}
    />
  );
};

const TextWithoutTypingAnimation = (props: TextMessageProps) => {
  useEffect(() => {
    props.onFinish(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MessageContent variant="body1">{props.message}</MessageContent>;
};

const BotMessage: React.FC<MessageProps> = ({
  messageIndex,
  message,
  actions,
  onClickAction,
  typingAnimation = true,
  isHtml,
  onFinishTyping,
}) => {
  const [finishedTyping, setFinishTyping] = useState<boolean>(false);
  const { finishedMessageRendering } = useTypingControl();

  const stopRenderingTyping = () => {
    console.log("Ending type animation");
    setFinishTyping(true);
    finishedMessageRendering(messageIndex);
    if (onFinishTyping) {
      onFinishTyping();
    }
  };

  return (
    <MessageContainer>
      <StyledListItemAvatar>
        <Avatar alt="Bot" src={botAbatar} />
      </StyledListItemAvatar>
      <Box sx={{ flexGrow: 1, pl: 1, pr: 5 }}>
        <StyledContent>
          {isHtml ? (
            <HtmlMessage message={message} onFinish={stopRenderingTyping} />
          ) : (
            <TextMessage
              message={message}
              onFinish={stopRenderingTyping}
              typingAnimation={typingAnimation}
            />
          )}
          <MessageActionsContainer
            show={finishedTyping}
            actions={actions}
            onClickAction={onClickAction}
            animation={typingAnimation}
          />
        </StyledContent>
      </Box>
    </MessageContainer>
  );
};

export default BotMessage;
